<template>
  <div>
    <div class="contentDetail contentDetailAll" v-if="imgBoxBlock==false?true:false">
      <div class="topHead" v-if="(appName)=='看埇桥'">
        <div class="topText">
          <img src="../../assets/logo_yongqiao.png" />
          <span>看埇桥</span>
        </div>
        <div class="back_button" @click="openWx()">打开</div>
      </div>
      <loading v-if="!detailAll" :iscomplete="!detailAll"></loading>
      <div class="vedioStyleBox" v-if="detailAll && detailAll.contributionVideo">
        <div class="coverImg" @click="vedioOpenPlay" v-if="thumbBlock">
          <img class="thumbImg" src="../../assets/bvd.png" alt />
          <img class="vedioButtonSty" src="../../assets/vedioSing.png" alt />
        </div>
        <video class="vedioContent" :src="detailAll.contributionVideo" controls id="datailVedio"></video>
      </div>
      <!-- 音频 -->
      <div class="vedioStyleBox" v-if="detailAll && detailAll.contributionAudio">
        <div class="coverImg">
          <img class="thumbImg" src="../../assets/vedioMoRen.png" alt />
          <img
            v-show="audioBlock"
            @click="audioOpenPlay"
            class="vedioButtonSty"
            src="../../assets/vedioSing.png"
            alt
          />
          <img
            v-show="!audioBlock"
            @click="audioOpenPlay"
            class="vedioButtonSty"
            src="../../assets/vplay.png"
            alt
          />
        </div>
        <audio class="vedioContent" :src="detailAll.contributionAudio" id="datailAudio"></audio>
      </div>

      <div class="contentAll" v-if="detailAll">
        <!-- <video :src="detailAll.video" controls  class="vedioContent"></video> -->
        <div class="contentTitltle">{{ detailAll.mainContributionTitle }}</div>
        <div class="F9efPzfcE5tLO0cygLjpo">
          <div class="_1rui3RTB5brFqhZq5nYylv"></div>
          <div class="Q46HXxwObg2rmy-Ms7f8I">{{ detailAll.source }}</div>
          <div class="Q46HXxwObg2rmy-Ms7f8I">{{ detailAll.releaseTime }}</div>
          <div class="_1rui3RTB5brFqhZq5nYylv"></div>
        </div>
        <div class="content" v-html="detailAll.content" id="contentde"></div>
        <div class="numberStyle" v-if="detailAll.content">阅读数：{{ number }}</div>
      </div>
      <div style="width: 100%; height: 8px; background: #f0f0f0"></div>
      <!-- <div
      class="commentContent commentContentOther"
      v-if="dataLIst && dataLIst.length"
    >
      <div class="columnTitle">-- 推荐 --</div>
      <newslist :listcontent="dataLIst"></newslist>
      </div>-->
      <div style="width: 100%; height: 8px; background: #f0f0f0"></div>
      <!-- 评论 -->
      <div class="commentContent" v-if="commentList && commentList.length > 0">
        <!-- <div class="columnTitle">-- 评论区 --</div> -->
        <ul class="columnContent">
          <li v-for="(item, index) in commentList" :key="index">
            <img
              :src="
              item.fromHeadPhoto
                ? item.fromHeadPhoto
                : require('../../assets/images/user.png')
            "
              alt
            />
            <div class="rootComment">
              <div class="userInfo">
                <span>{{ item.fromName ? item.fromName : "未知用户" }}</span>
                <span @click="onReply(item)">回复</span>
              </div>
              <p>{{ item.releaseContent }}</p>
              <div
                v-if="item.childsize"
                class="replyBox"
                @click="openReplyPop(item)"
              >共{{ item.childsize }}条回复 ></div>
            </div>

            <!-- 弹窗中的评论与回复 -->
            <van-popup
              v-model="replayPop"
              closeable
              round
              position="bottom"
              :style="{ height: '80%', background: '#F2F2F2' }"
            >
              <div class="reply_title">{{ popContent.childsize }}条回复</div>
              <div class="reply_head reply_back">
                <img
                  :src="
                  popContent.fromHeadPhoto
                    ? popContent.fromHeadPhoto
                    : require('../../assets/images/user.png')
                "
                  alt
                />
                <div class="head_comment">
                  <div class="userInfo">
                    <span>
                      {{
                      popContent.fromName ? popContent.fromName : "未知用户"
                      }}
                    </span>
                  </div>
                  <p>{{ popContent.releaseContent }}</p>
                </div>
              </div>
              <div style="background: #ddd; height: 10px"></div>
              <div v-for="replyItem in replyList" :key="replyItem.id">
                <div class="reply_head">
                  <img
                    :src="
                    replyItem.fromHeadPhoto
                      ? replyItem.fromHeadPhoto
                      : require('../../assets/images/user.png')
                  "
                    alt
                  />
                  <div class="head_comment head_border">
                    <div class="userInfo">
                      <span>
                        {{
                        replyItem.fromName ? replyItem.fromName : "未知用户"
                        }}
                      </span>

                      <span @click="onReply(replyItem)">回复</span>
                    </div>
                    <!-- 二级回复 -->
                    <div v-if="replyItem.parentId !== popContent.id">
                      <span>回复</span>
                      <span style="color: #107da8">@{{ replyItem.toName }}:</span>
                      <span>{{ replyItem.releaseContent }}</span>
                    </div>
                    <!-- 一级回复 -->
                    <p v-if="replyItem.parentId === popContent.id">{{ replyItem.releaseContent }}</p>
                  </div>
                </div>
              </div>
              <div class="btn_wra" v-show="moreReply">
                <van-button
                  class="rep_mor"
                  loading-type="spinner"
                  :loading="reply_load"
                  @click="showMoreReply"
                >点击展示更多回复</van-button>
              </div>
              <div v-show="emptyReply" class="emp_txt">暂无更多回复</div>
            </van-popup>
          </li>
        </ul>
        <div class="btn_wra" v-show="moreComment">
          <van-button
            class="rep_mor"
            loading-type="spinner"
            :loading="comment_load"
            @click="showMoreComment"
          >点击展示更多评论</van-button>
        </div>
        <div v-show="emptyComment" class="emp_txt">暂无更多数据</div>
      </div>
    </div>
    <div class="wxPic" v-if="imgBoxBlock==true?true:false">
      <img src="../../assets/openLink.jpg" />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Toast } from "vant";
import loading from "../cllComponents/loading";
// import PraiseCollect from "./PraiseCollect";
import Homeapi from "@/api/home";
import ArticleApi from "@/api/article";
import { shareData } from '@/api/share.js'
import wx from 'weixin-js-sdk';
export default {
  components: {
    loading,
    // PraiseCollect,
  },
  data() {
    return {
      imgBoxBlock: false,
      detailAll: {},
      params: {
        contributionId: "",
        appName: this.appName,
        memberId: window.localStorage.userinfo
          ? JSON.parse(window.localStorage.userinfo).id
          : "",
      },
      thumbBlock: true,
      audioBlock: true,
      showFabulous: false, // 是否点赞
      showCollection: false, // 是否收藏
      number: 0, // 文章阅读数
      commentList: [],
      popContent: {}, // 弹窗中的评论内容
      replyList: [], // 评论下的回复列表
      show: false, // 评论框展示
      viewpoint: "", // 评论内容
      viewpointParent: null, // 如果是对评论进行评论，则此变量存放被评论的id
      replayPop: false,
      isHeart: false,
      page: 1,
      reply_page: 1,
      commentId: "", // 评论Id
      replyId: "",
      moreReply: false, // 展示更多回复的按钮
      moreComment: false,
      reply_load: false, // 按钮加载状态
      comment_load: false,
      emptyComment: false,
      emptyReply: false,
    };
  },
  created() {
    var id = window.location.href.split("&judge")[0].split("?id=")[1];
    this.datalinkDetail(id);
  },
  watch: {
    show: function (dNew) {
      if (!dNew) {
        // 保证在关闭评论框后保存的评论父级id为空
        this.viewpointParent = null;
      }
    },
  },
  methods: {
    //打开微信
    openWx() {
      var WX = /(micromessenger)/i;
      var USER_Agent = navigator.userAgent;
      if (WX.test(USER_Agent)) {
        this.imgBoxBlock = true; //提示用默认浏览器打开
        return;
      } else { //协议
        window.location.href = "yqapp://kanyongqiao/openwith?id=" + this.detailAll.id + "&title=" + this.detailAll.mainContributionTitle + "&fromType=news";
      }
      //如果没有唤起app则3秒后会跳转到下载地址，反之则不会跳转到下载地址。
      setTimeout(function () {
        window.location.href = "http://www.yongqiaonews.com/apk/";//下载地址
      }, 3000);
    },
    // 获取微信的token值
    wxinTokenLink() {
      this.$nextTick(() => {
        var url = window.location.href;
        var appName = this.appName;
        var news = this.shareFun();
        shareData({ url: url, appName: appName }).then((data) => {
          //console.log("接口数据",data.data)
          var imgSrc = data.data.avatar;
          //通过config接口注入权限验证配置 
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: data.data.appid, // 必填，公众号的唯一标识
            timestamp: data.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: data.data.noncestr, // 必填，生成签名的随机串
            signature: data.data.signature,// 必填，签名
            jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'] // 必填，需要使用的JS接口列表

          });
          // 通过ready接口处理成功验证
          wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
            wx.updateAppMessageShareData({
              title: news.title,  // 分享标题
              desc: news.describe,   // 分享描述
              link: news.url,   // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: imgSrc, // 分享图标
            }),
              wx.updateTimelineShareData({
                title: news.title,  // 分享标题
                desc: news.describe,   // 分享描述
                link: news.url,   // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: imgSrc, // 分享图标
              })
          });
        }).catch(err => {
          //console.log(err)
        })
      })
    },
    // 分享
    shareFun() {
      var describe;
      var link;
      if (this.detailAll.content) {
        describe = document.getElementById("contentde").innerText.replace(/&nbsp;/g, "").slice(0, 50);
      } else {
        describe = '';
      }
      if (window.location.href.search("&judge") == -1) {
        if (this.detailAll.linkerapp) {
          link = this.detailAll.linkerapp; //链接
        } else {
          link = window.location.href.split("#/")[0] + "?#/wxShare_contentDetail?id=" + this.detailAll.id; //链接
        }
      } else {
        link = window.location.href.split("#/")[0] + "?#/wxShare_contentDetail?id=" + this.detailAll.medid_id + '&judge'; //链接
      }
      return { url: link, title: this.detailAll.mainContributionTitle, describe: describe };
      // this.shareContent({url: link,title:this.detailAll.title,thumb:this.detailAll.thumb.split(',')[0],describe:describe});
    },
    // 获取数据
    datalinkDetail(id) {
      let params = {
        id,
      };
      Homeapi.newsDratil(params).then((res) => {
        if (res.success) {
          //console.log(res);
          this.detailAll = res.data;
          this.detailAll.content = this.detailAll.contributionContent
            .replace(/&lt;/g, "<")
            .replace(/&gt;/g, ">");
          this.params.contributionId = this.detailAll.id;
          this.queryReadNum();
          this.addReadNum();
          if (
            window.localStorage.userinfo &&
            JSON.parse(window.localStorage.userinfo).id
          ) {
            this.queryIsAlreadyPraise();
            this.queryIsCollectArticle();
            this.queryComment();
          }
          if (this.appName !== '爱旌德') {
            this.wxinTokenLink()
          }


        }
      });
    },
    // 点赞
    onFabulous() {
      if (this.isToken()) {
        ArticleApi.addOrMovePriase(this.params).then((resp) => {
          if (this.detailAll.matrix_judge == 1) {
            this.toReload("whole"); //刷新全部
          }
          this.showFabulous = !this.showFabulous;
        });
      }
    },

    // 收藏
    onCollection() {
      if (this.isToken()) {
        ArticleApi.addOrMoveCollection(this.params).then((resp) => {
          if (this.detailAll.matrix_judge == 1) {
            this.toReload("whole"); //刷新全部
          }
          this.showCollection = !this.showCollection;
        });
      }
    },

    // 查询更多回复
    showMoreReply() {
      this.reply_page += 1;
      this.reply_load = true;
      this.queryReplys();
    },
    showMoreComment() {
      this.page += 1;
      this.comment_load = true;
      this.queryComment();
    },
    // 查询文章的阅读数
    queryReadNum() {
      ArticleApi.getArticleNum(this.params).then((resp) => {
        this.number = resp.data;
      });
    },
    // 增加文章的阅读数
    addReadNum() {
      ArticleApi.addArticleNum(this.params).then(() => { });
    },
    // 查询是否点赞
    queryIsAlreadyPraise() {
      ArticleApi.isPraise(this.params).then((resp) => {
        // console.log(resp.data);
        if (resp.data.isParise) {
          // 点赞了
          this.showFabulous = true;
        } else {
          this.showFabulous = false;
        }
      });
    },
    // 查询是否收藏文章
    queryIsCollectArticle() {
      ArticleApi.isCollect(this.params).then((resp) => {
        if (resp.data) {
          this.showCollection = true;
        } else {
          this.showCollection = false;
        }
      });
    },
    // 查询评论
    queryComment() {
      // console.log("评论");
      const params = {
        appName: this.appName,
        page: this.page,
        rows: 10,
        contributionId: this.params.contributionId,
        id: this.commentId,
      };
      ArticleApi.getCommentList({}, params).then((res) => {
        this.comment_load = false;
        if (res.data.items && res.data.items.length > 0) {
          if (this.page === 1) {
            this.commentList = [];
          }
          this.commentList = this.commentList.concat(res.data.items);
        }
        if (res.data.items.length < 10) {
          // 小于十条，说明不足展示一页
          this.emptyComment = true;
          this.moreComment = false;
        } else {
          this.moreComment = true;
          this.emptyComment = false;
        }
      });
    },
    // 发表按钮
    onPublish() {
      if (!this.viewpoint) {
        return Toast("评论不能为空");
      }
      const contributionCommentDTO = {
        contributionId: this.params.contributionId,
        id: 0,
        parentId: this.viewpointParent ? this.viewpointParent.id : 0,
        releaseContent: this.viewpoint,
      };
      const params = {
        appName: this.appName,
        memberId: JSON.parse(window.localStorage.userinfo).id,
      };
      console.log(contributionCommentDTO);
      ArticleApi.addComment(contributionCommentDTO, params)
        .then((resp) => {
          //console.log("发表评论");
          //console.log(resp.data);
          if (resp.success) {
            Toast(resp.message);
            this.show = false;
            this.viewpoint = "";
            this.page = 1;
            this.reply_page = 1;
            this.queryComment();
            this.queryReplys();
          } else {
            Toast.fail("发表失败");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 回复评论
    onReply(value) {
      // 评论前判断是否已经登录
      // console.log(value);
      if (this.isToken()) {
        this.show = true;
        this.viewpointParent = value;
      }
    },
    // 打开回复框
    openReplyPop(value) {
      // console.log(value);
      this.reply_page = 1;
      this.popContent = value;
      this.replyId = value.id;
      this.queryReplys();
      this.replayPop = true;
    },
    // 查询评论下的回复
    queryReplys() {
      const params = {
        appName: this.appName,
        page: this.reply_page,
        rows: 5,
        contributionId: this.params.contributionId,
        id: this.replyId,
      };
      ArticleApi.getCommentList({}, params).then((res) => {
        // console.log("查询评论下的回复");
        this.reply_load = false;
        if (res.data.items && res.data.items.length > 0) {
          if (this.reply_page === 1) {
            this.replyList = [];
          }
          this.replyList = this.replyList.concat(res.data.items);
        }
        if (res.data.items.length < 5) {
          // 小于十条，说明不足展示一页
          this.emptyReply = true;
          this.moreReply = false;
        } else {
          this.moreReply = true;
          this.emptyReply = false;
        }
      });
    },
    // 分享
    shareData() {
      var describe = document
        .getElementById("contentde")
        .innerText.replace(/&nbsp;/g, "")
        .slice(0, 50);
      var link;
      // console.log(describe);
      if (this.detailAll.linkerapp) {
        link = this.detailAll.linkerapp; //链接
      } else {
        link =
          window.location.href.split("#/")[0] + "#/sharePage?id=" + this.detailAll.id; //链接
      }
      this.shareContent({
        url: link,
        title: this.detailAll.mainContributionTitle,
        // thumb: this.detailAll.contributionCover.split(",")[0],
        describe: describe,
      });
    },
    // 视频播放
    vedioOpenPlay() {
      var audio = document.getElementById("datailAudio");
      if (audio) {
        audio.pause();
      }
      var video = document.getElementById("datailVedio");
      if (video) {
        if (video.paused) {
          //如果已暂停则播放
          this.thumbBlock = false;
          video.play(); //暂停控制
        }
      }
    },
    audioOpenPlay() {
      var video = document.getElementById("datailVedio");
      if (video) {
        this.thumbBlock = true;
        this.$forceUpdate();
        video.pause();
      }
      var audio = document.getElementById("datailAudio");
      if (audio) {
        if (audio.paused) {
          //如果已暂停则播放
          this.audioBlock = false;
          audio.play();
        } else {
          this.audioBlock = true;
          audio.pause();
        }
        audio.addEventListener(
          "pause",
          () => {
            this.audioBlock = true;
            this.$forceUpdate();
            audio.pause();
          },
          false
        );
      }
    },
  },
};
</script>
<style>
.contentDetailAll .content {
  text-align: justify;
  /* font-family: PingFang SC,Helvetica,Arial,\\5FAE\8F6F\96C5\9ED1,\\9ED1\4F53; */
  line-height: 0.62rem;
  font-size: 0.38rem;
}
.contentDetailAll .content * {
  max-width: 100% !important;
}
.contentDetailAll .content img {
  height: auto !important;
  width: 100%;
}
.contentDetailAll .content video {
  height: auto !important;
}
.contentDetailAll .van-overlay {
  z-index: 9999;
}
</style>
<style lang="less" scoped>
.wxPic {
  img {
    width: 100%;
    height: 100%;
    margin-top: 1rem;
  }
}
.topHead {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  box-shadow: 5px 5px 5px #dadada;
  align-items: center;
  background-color: #fff;
  .topText {
    margin-left: 10px;
    span {
      line-height: 80px;
    }
    img {
      width: 35px;
      transform: translateY(11px);
    }
  }
  .back_button {
    margin-right: 20px;
    background-color: red;
    height: 30px;
    border-radius: 15px;
    width: 1.6rem;
    font-size: 15px;
    color: #fff;
    text-align: center;
    line-height: 30px;
  }
}
.contentTitltle {
  font-size: 0.44rem;
  line-height: 0.64rem;
  // font-family: PingFang SC,Helvetica,Arial,\\5FAE\8F6F\96C5\9ED1,\\9ED1\4F53;
  color: #222;
  font-weight: 600;
  padding-top: 20px;
  /* font-weight: 700; */
}
.F9efPzfcE5tLO0cygLjpo {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 0.3rem;
  ._1rui3RTB5brFqhZq5nYylv {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1;
    -ms-flex: 1 1;
    flex: 1 1;
    height: 0;
    border-bottom: 1px solid #eaeaea;
  }
  .Q46HXxwObg2rmy-Ms7f8I {
    color: #9a9a9a;
    font-size: 0.26rem;
    margin-left: 0.1rem;
    margin-right: 0.1rem;
  }
}
.numberStyle {
  width: 100%;
  text-align: right;
  font-size: 12px;
  height: 20px;
  line-height: 20px;
  color: #999;
}
.contentDetail {
  width: 100%;
  height: 100%;
}
.contentAll {
  width: 100%;
  // height: 100%;
  padding: 15px 15px 60px;
  box-sizing: border-box;
  font-size: 16px;
  padding-top: 0px;
}
.content {
  width: 100%;
}
.vedioContent {
  width: 100%;
  height: 190px;
  object-fit: contain;
}
// .contentTitltle{
//     font-size: 20px;
//     /* font-weight: 700; */
// }
.sourceAndTime {
  width: 100%;
  font-size: 12px;
  line-height: 40px;
  color: #999;
}
.sourceFont {
  width: 78%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.sourceAndTimeJava {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sourceFontnei {
  font-size: 12px;
  margin-left: 10px;
}
.buttonGuanzhu {
  width: 22%;
  background: #fff;
  outline: none;
  border: 1px solid #e32416;
  color: #e32416;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  padding: 5px;
  border-radius: 100px;
}
.buttonGuanzhu .iconfont {
  font-size: 12px;
}
.sourceFontImg {
  width: 30px;
  height: 30px;
}
.sourceFontImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
}
.shareContent {
  position: fixed;
  bottom: 15px;
  right: 15px;
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.shareContent span {
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background: #fff;
  margin-left: 2px;
}
.shareContent span:last-child {
  margin-right: 2px;
}

.vedioStyleBox {
  width: 100%;
  height: 190px;
  position: relative;
  margin: 12px 0;
}
.coverImg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 999;
}
.thumbImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.vedioButtonSty {
  width: 50px;
  height: 50px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -25px;
  margin-top: -25px;
  z-index: 1000;
}
.vedioStyle {
  width: 100%;
  height: 190px;
  object-fit: contain;
}
.audioContentBox {
  width: 100%;
  // margin-top: 7px;
  // height: 190px;
  position: relative;
  .v_for {
    position: relative;
    z-index: 1;
    .thumbImg {
      width: 100%;
      height: 190px;
    }
    .vedioButtonSty {
      width: 36px;
      height: 36px;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -18px;
      margin-top: -18px;
      z-index: 10;
    }
  }
  .vedioContent {
    width: 100%;
    height: 190px;
    object-fit: contain;
  }
}
.userOpeeration > div {
  width: 20px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
}
.heart {
  animation: heartbeat 1s;
}
.userOpeeration {
  width: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  display: flex;
  padding: 10px 15px;
  background-color: #fff;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  border-top: 1px solid #f2f3f5;
  img {
    width: 33px;
  }
  .comment {
    display: flex;
    align-items: center;
    line-height: 20px;
    // width: calc(100% - 150px);
    width: calc(100% - 88px);
    border: 1px solid #eee;
    border-radius: 7px;
    padding: 5px 8px;
    background-color: #fff;
    color: #666;
    font-size: 14px;
    background: #f2f3f5;
    img {
      width: 35px;
      height: 30px;
    }
  }
}
.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
}
.commentBlock {
  width: 100%;
  padding: 10px 20px;
  background-color: #fff;
}
.commentBlock textarea {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 10px;
  box-sizing: border-box;
  resize: none;
  font-size: 16px;
}
.commentBlock div {
  display: flex;
  justify-content: space-between;
}
.commentBlock div button {
  font-size: 14px;
  outline: none;
  border: 0;
  background-color: #fff;
  padding: 3px 8px;
}
.commentContent {
  width: 100%;
  margin-bottom: 55px;
  .columnTitle {
    text-align: center;
    padding: 5px 0;
  }
  .columnContent {
    height: calc(100% - 80px);
    li {
      padding: 10px 15px;
      overflow: hidden;
      display: flex;
      img {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        background-color: #107da8;
        padding: 3px;
        box-sizing: border-box;
      }
      .rootComment {
        width: calc(100% - 22px);
        padding: 0 10px;
        box-sizing: border-box;
        border-bottom: 1px solid #efefef;
        padding-bottom: 10px;
        .userInfo {
          display: flex;
          justify-content: space-between;
          line-height: 22px;
          span {
            font-size: 12px;
            &:last-child {
              color: #bbb;
            }
          }
        }
        .replyBox {
          margin-top: 10px;
          padding: 3px 0 3px 15px;
          background: #efefef;
          color: #107da8;
        }
        p {
          font-size: 14px;
        }
        .childComment {
          overflow: hidden;
          display: flex;
          margin-top: 10px;
          padding-top: 10px;
          border-top: 1px solid #efefef;
          > div {
            width: calc(100% - 22px);
            padding-left: 10px;
            box-sizing: border-box;
          }
        }
      }
    }
  }
  .noComment {
    text-align: center;
    height: 100px;
    line-height: 100px;
  }
}
.commentContentOther {
  margin-bottom: 0px;
}
.reply_title {
  text-align: center;
  padding: 12px 0 10px;
  // margin-top: 10px;
  border-bottom: 1px solid#efefef;
  background: #fff;
}
.btn_wra {
  width: 50vw;
  margin: 0 auto;
  padding: 2vw 0;
  .rep_mor {
    width: 100%;
    height: 8vw;
    background: #1989fa;
    text-align: center;
    line-height: 8vw;
    color: #fff;
  }
}
.emp_txt {
  text-align: center;
  color: rgba(168, 166, 166, 0.863);
  font-size: 14px;
  padding: 4px 0;
}
.reply_head {
  display: flex;
  padding: 5px 0 0 10px;
  .head_comment {
    width: calc(100% - 22px);
    padding: 0 10px 10px;
    box-sizing: border-box;
    .userInfo {
      display: flex;
      justify-content: space-between;
      line-height: 22px;
      span {
        font-size: 13px;
        &:last-child {
          color: #bbb;
        }
      }
    }
  }
  .head_border {
    border-bottom: 1px solid #ddd;
  }
}
.reply_back {
  background: #fff;
}
@keyframes heartbeat {
  0% {
    transform: scale(0.8, 0.8);
    opacity: 1;
  }
  25% {
    transform: scale(1, 1);
    opacity: 0.8;
  }
  100% {
    transform: scale(0.8, 0.8);
    opacity: 1;
  }
}
</style>
